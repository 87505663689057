@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply h-auto max-w-96 rounded-3xl bg-primary px-16 py-4 text-white shadow-button transition hover:bg-tertiary active:scale-95 active:bg-accent-secondary;
  }

  .link {
    @apply text-lg text-black no-underline;
  }

  .link-container {
    @apply flex items-center px-5 py-3;
  }

  .flyout-link {
    @apply flex h-10 items-center rounded-r-md pl-10 text-xl no-underline;
  }

  .flyout-link-container {
    @apply py-2;
  }

  .bar {
    @apply my-[3px] h-1 w-9 rounded-xl bg-black;
  }

  .note-end {
    font-size: x-large;
    text-align: end;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&family=Bayon&family=Cantora+One&family=Chicle&family=KoHo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Koh+Santepheap:wght@100;300;400;700;900&display=swap");
@font-face {
  font-family: "Acherone";
  src: local("Acherone"), url("./fonts/Acherone/Acherone.ttf");
}
@font-face {
  font-family: "Neville";
  src: local("Neville"), url("./fonts/Neville.ttf");
}
@font-face {
  font-family: "Circular-Medium";
  src:
    local("Circular"),
    url("./fonts/Circular/CircularStd-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Circular-Book";
  src:
    local("Circular"),
    url("./fonts/Circular/CircularStd-Book.otf") format("opentype");
}

@font-face {
  font-family: "Circular-Light";
  src:
    local("Circular"),
    url("./fonts/Circular/CircularStd-Light.otf") format("opentype");
}

@font-face {
  font-family: "Lazydog";
  src:
    local("Lazydog"),
    url("./fonts/Lazydog/Lazydog.otf") format("opentype");
}

body,
p {
  @apply text-lg/6;
  font-family: "Assistant";
}

body {
  margin: 0;
}

h1 {
  @apply text-4xl;
  font-family: "Acherone";
}

h2,
h3 {
  font-family: "Chicle";
}

h2 {
  @apply text-3xl tracking-wider;
}

h3 {
  @apply text-2xl tracking-wide;
}

a {
  @apply text-tertiary underline;
}

body.modal-open {
  overflow: hidden;
}

/* input[type="text"],
input[type="number"],
input[type="email"],
textarea {
  font-size: 18px;
} */

#content.disable {
  overflow: hidden;
  position: fixed;
}
